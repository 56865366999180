import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, updateProfile } from "firebase/auth";
import { db } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { DatePickerWithRange } from "./DateRangePicker";
import { format, startOfDay, endOfDay } from "date-fns";
import { useLocation } from "react-router-dom";
import Header from "./Header";

const CreateEvent = ({ onClose, onEventCreated, onBackToDashboard }) => {
  const [name, setName] = useState("");
  const [eventName, setEventName] = useState("");
  const location = useLocation(); // Get current location

  const [dateRange, setDateRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [touched, setTouched] = useState({
    name: false,
    eventName: false,
    dateRange: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && user.displayName) {
      setName(user.displayName);
      setTouched((prev) => ({ ...prev, name: true }));
    }
  }, []);

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setTouched((prev) => ({ ...prev, [e.target.name]: true }));
  };

  const handleDateRangeChange = (range) => {
    setDateRange(range);
    setTouched((prev) => ({ ...prev, dateRange: true }));
  };

  const validateForm = () => {
    return (
      name.trim() !== "" &&
      eventName.trim() !== "" &&
      dateRange &&
      dateRange.from &&
      dateRange.to
    );
  };

  const getInputClassName = (fieldName) => {
    const baseClasses =
      "block w-full rounded-md border py-1.5 shadow-sm focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6";
    if (!touched[fieldName]) return `${baseClasses} border-gray-300`;
    return touched[fieldName] && !eval(fieldName).trim()
      ? `${baseClasses} border-red-500 focus:border-red-500`
      : `${baseClasses} border-green-500 focus:border-green-500`;
  };

  const handleEventCreate = async () => {
    if (!validateForm()) {
      setTouched({
        name: true,
        eventName: true,
        dateRange: true,
      });
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      if (name !== user.displayName) {
        try {
          await updateProfile(user, { displayName: name });
          console.log("Display name updated successfully");
        } catch (error) {
          console.error("Error updating display name:", error);
        }
      }

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const existingEvents = userData.events || {};

        const newEvent = {
          eventName,
          timeframe: {
            startDate: format(dateRange.from, "yyyy-MM-dd"),
            endDate: format(dateRange.to, "yyyy-MM-dd"),
          },
          selectedDates: [],
          friends: {},
          createdAt: new Date(),
          name: name,
          uid: user.uid,
        };

        await updateDoc(userDocRef, {
          [`events.${eventName}`]: newEvent,
        });

        if (onEventCreated) {
          onEventCreated(eventName);
        } else {
          navigate(
            `/select-dates/${user.uid}/${encodeURIComponent(eventName)}`
          );
        }

        if (onClose) onClose();
      }
    }
  };

  return (
    <div>
      {location.pathname == "/create" && <Header />}

      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
        <button
          onClick={onBackToDashboard}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
            Create an Event
          </h2>
          <p className="mt-2 text-center text-sm leading-6 text-gray-600">
            Enter your event details to get started.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12 ring-1 ring-inset ring-gray-300">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => handleInputChange(e, setName)}
                    required
                    className={getInputClassName("name")}
                    placeholder="Jane Doe"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="event-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Event Name
                </label>
                <div className="mt-2">
                  <input
                    id="event-name"
                    name="eventName"
                    type="text"
                    value={eventName}
                    onChange={(e) => handleInputChange(e, setEventName)}
                    required
                    className={getInputClassName("eventName")}
                    placeholder="Holiday Party"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="date-range"
                  className="block text-sm font-medium leading-6 text-gray-900 font-semibold"
                >
                  Check friends availability
                </label>
                <div className="mt-2">
                  <p className="my-2 mb-4 text-sm text-gray-600">
                    Between the following dates:
                  </p>

                  <DatePickerWithRange
                    date={dateRange}
                    setDate={handleDateRangeChange}
                    className="w-full"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleEventCreate}
                  disabled={!validateForm()}
                  className={`w-full rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${
                    validateForm()
                      ? "bg-black hover:bg-[#555555]"
                      : "bg-gray-300 cursor-not-allowed"
                  }`}
                >
                  Create Event
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
