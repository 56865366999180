import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

const FeedbackForm = () => {
  const [feedbackType, setFeedbackType] = useState("Bug");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      await addDoc(collection(db, "feedback"), {
        type: feedbackType,
        description: description,
        userId: user ? user.uid : "anonymous",
        createdAt: new Date(),
      });

      // Clear the form
      setFeedbackType("Bug");
      setDescription("");
      alert("Thank you for your feedback!");
    } catch (error) {
      console.error("Error submitting feedback: ", error);
      alert("There was an error submitting your feedback. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-black text-4xl font-black leading-tight">
        Give Us Feedback
      </h2>
      <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
        Your feedback helps shape the future of the app! Let us know about any
        bugs or features you'd like to see.
      </p>
      <form className="space-y-4 mt-4" onSubmit={handleSubmit}>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Feedback Type
          </label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
          >
            <option>Bug</option>
            <option>Feature Request</option>
            <option>Other</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Feedback"}
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;
