import logo from "./logo.svg";
import "react-day-picker/style.css";

import "./App.css";
import { Routes, Route, Router } from "react-router-dom";
import Login from "./components/Login";
import CreateEvent from "./components/CreateEvent";
import SelectDates from "./components/SelectDates";
import Availability from "./components/Availability";
import ShareLink from "./components/ShareLink";
import Dashboard from "./components/Dashboard";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import EventDetails from "./components/EventDetails";
import LandingPage from "./components/LandingPage";
import Signup from "./components/Signup";
import ThankYou from "./components/ThankYou";
import Verify from "./components/Verify";
import Header from "./components/Header";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/create" element={<CreateEvent />} />
        <Route path="/verify" element={<Verify />} />
        <Route
          path="/select-dates/:uuid/:eventName"
          element={<SelectDates />}
        />
        <Route path="/share-link/:uuid/:eventName" element={<ShareLink />} />
        <Route path="/availability" element={<Availability />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* Event Details route */}
        <Route
          path="/dashboard/event/:eventName"
          element={
            <ProtectedRoute>
              <EventDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
