import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import Header from "./Header";
import { GoogleLogin } from "@react-oauth/google";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Make sure Firestore is initialized
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const [errorMessage, setErrorMessage] = useState("");

  // Handle Email/Password Login
  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Logged in successfully:", userCredential.user);
      navigate("/dashboard");
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setErrorMessage("Invalid email address.");
          break;
        case "auth/user-disabled":
          setErrorMessage("This account has been disabled.");
          break;
        case "auth/user-not-found":
          setErrorMessage("No account found with this email.");
          break;
        case "auth/wrong-password":
          setErrorMessage("Incorrect password.");
          break;
        default:
          setErrorMessage("Failed to login. Please try again.");
      }
      console.error("Error logging in: ", error);
    }
  };

  // Handle Google Login and create Firestore document if necessary
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider); // Perform Google login
      const user = result.user; // Get the signed-in user

      // Check if the user's document exists in Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      // If the document doesn't exist, create it
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          uid: user.uid,
          name: user.displayName,
          events: [], // Initialize with empty events array
        });
        console.log("New user document created in Firestore");
      }

      console.log("Logged in with Google successfully!");
      navigate("/dashboard"); // Redirect to the dashboard after successful login
    } catch (error) {
      setErrorMessage("Google login failed. Please try again.");
      console.error("Error during Google login:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header /> {/* Header takes natural height */}
      {/* The login form takes up the remaining space */}
      <div className="flex-grow flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-[#f8f8f8]">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {errorMessage && (
              <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
            )}

            <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  className="align-center flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-[#555] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div className="mt-5">
              <button
                onClick={handleGoogleLoginSuccess}
                className="flex items-center h-[50px] justify-center w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <FcGoogle className="mr-3 size-5" />
                Sign in with Google
              </button>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{" "}
              <button
                onClick={() => navigate("/signup")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
