// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQ-5WXPAloQ1viOiG7tRpIIC-SC8YDcOA",
  authDomain: "madeitout-io.firebaseapp.com",
  projectId: "madeitout-io",
  storageBucket: "madeitout-io.appspot.com",
  messagingSenderId: "46431071407",
  appId: "1:46431071407:web:8fa52db498e9ab1021785a",
  measurementId: "G-2G6SCCZ849"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);