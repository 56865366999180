import React from 'react';
import '../App.css'; // Assuming you'll add the CSS for the loader here

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
