import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />

      <div className="container mx-auto p-8">
        <p>
          <strong>PRIVACY POLICY</strong>
        </p>
        <p>&nbsp;</p>
        <p>Last updated October 18, 2024</p>

        <p>&nbsp;</p>
        <p>
          This Privacy Notice for Made It Out ('we', 'us', or 'our'), describes
          how and why we might access, collect, store, use, and/or share
          ('process') your personal information when you use our services
          ('Services'), including when you:
        </p>
        <p>
          Visit our website at&nbsp;
          <a href="https://madeitout.io/">https://madeitout.io</a>, or any
          website of ours that links to this Privacy Notice
        </p>
        <p>
          Use Made It Out. A web app that allows you to plan &amp; share events
          easily with your friends.
        </p>
        <p>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </p>
        <p>
          Questions or concerns? Reading this Privacy Notice will help you
          understand your privacy rights and choices. We are responsible for
          making decisions about how your personal information is processed. If
          you do not agree with our policies and practices, please do not use
          our Services. If you still have any questions or concerns, please
          contact us at&nbsp;
          <a href="mailto:hello@madeitout.io">hello@madeitout.io</a>.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>SUMMARY OF KEY POINTS</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          This summary provides key points from our Privacy Notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <p>&nbsp;</p>
        <p>
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with us and the Services, the choices you make,
          and the products and features you use. Learn more about personal
          information you disclose to us.
        </p>
        <p>&nbsp;</p>
        <p>
          Do we process any sensitive personal information? Some of the
          information may be considered 'special' or 'sensitive' in certain
          jurisdictions, for example your racial or ethnic origins, sexual
          orientation, and religious beliefs. We do not process sensitive
          personal information.
        </p>
        <p>&nbsp;</p>
        <p>
          Do we collect any information from third parties? We do not collect
          any information from third parties.
        </p>
        <p>&nbsp;</p>
        <p>
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Learn more about how we process your information.
        </p>
        <p>&nbsp;</p>
        <p>
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties. Learn more about when and with whom we share
          your personal information.
        </p>
        <p>&nbsp;</p>
        <p>
          How do we keep your information safe? We have adequate organisational
          and technical processes and procedures in place to protect your
          personal information. However, no electronic transmission over the
          internet or information storage technology can be guaranteed to be
          100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about how we keep your information
          safe.
        </p>
        <p>&nbsp;</p>
        <p>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about your
          privacy rights.
        </p>
        <p>&nbsp;</p>
        <p>
          How do you exercise your rights? The easiest way to exercise your
          rights is by submitting a data subject access request, or by
          contacting us. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          Want to learn more about what we do with any information we collect?
          Review the Privacy Notice in full.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>TABLE OF CONTENTS</strong>
        </p>
        <p>&nbsp;</p>
        <p>1. WHAT INFORMATION DO WE COLLECT?</p>
        <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
        <p>
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
          INFORMATION?
        </p>
        <p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
        <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
        <p>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
        <p>7. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        <p>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        <p>9. DO WE COLLECT INFORMATION FROM MINORS?</p>
        <p>10. WHAT ARE YOUR PRIVACY RIGHTS?</p>
        <p>11. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        <p>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        <p>13. DO WE MAKE UPDATES TO THIS NOTICE?</p>
        <p>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
        <p>
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </p>
        <p>&nbsp;</p>
        <p>Personal information you disclose to us</p>
        <p>&nbsp;</p>
        <p>In Short: We collect personal information that you provide to us.</p>
        <p>&nbsp;</p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>&nbsp;</p>
        <p>
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </p>
        <p>names</p>
        <p>email addresses</p>
        <p>Sensitive Information. We do not process sensitive information.</p>
        <p>&nbsp;</p>
        <p>
          Social Media Login Data. We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, X, or other social media account. If you choose to
          register in this way, we will collect certain profile information
          about you from the social media provider, as described in the section
          called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
        </p>
        <p>&nbsp;</p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <p>&nbsp;</p>
        <p>Information automatically collected</p>
        <p>&nbsp;</p>
        <p>
          In Short: Some information &mdash; such as your Internet Protocol (IP)
          address and/or browser and device characteristics &mdash; is collected
          automatically when you visit our Services.
        </p>
        <p>&nbsp;</p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>&nbsp;</p>
        <p>The information we collect includes:</p>
        <p>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in the Services (such as the date/time
          stamps associated with your usage, pages and files viewed, searches,
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called 'crash dumps'), and hardware settings).
        </p>
        <p>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </p>
        <p>
          Location Data. We collect location data such as information about your
          device's location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device
          you use to access the Services. For example, we may use GPS and other
          technologies to collect geolocation data that tells us your current
          location (based on your IP address). You can opt out of allowing us to
          collect this information either by refusing access to the information
          or by disabling your Location setting on your device. However, if you
          choose to opt out, you may not be able to use certain aspects of the
          Services.
        </p>
        <p>Google API</p>
        <p>&nbsp;</p>
        <p>
          Our use of information received from Google APIs will adhere to Google
          API Services User Data Policy, including the Limited Use requirements.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>&nbsp;</p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <p>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </p>
        <p>
          To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </p>
        <p>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          To enable user-to-user communications. We may process your information
          if you choose to use any of our offerings that allow for communication
          with another user.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          To save or protect an individual's vital interest. We may process your
          information when necessary to save or protect an individual&rsquo;s
          vital interest, such as to prevent harm.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We only process your personal information when we believe it
          is necessary and we have a valid legal reason (i.e. legal basis) to do
          so under applicable law, like with your consent, to comply with laws,
          to provide you with services to enter into or fulfil our contractual
          obligations, to protect your rights, or to fulfil our legitimate
          business interests.
        </p>
        <p>&nbsp;</p>
        <p>If you are located in the EU or UK, this section applies to you.</p>
        <p>&nbsp;</p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <p>
          Consent. We may process your information if you have given us
          permission (i.e. consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time. Learn
          more about withdrawing your consent.
        </p>
        <p>
          Performance of a Contract. We may process your personal information
          when we believe it is necessary to fulfil our contractual obligations
          to you, including providing our Services or at your request prior to
          entering into a contract with you.
        </p>
        <p>
          Legal Obligations. We may process your information where we believe it
          is necessary for compliance with our legal obligations, such as to
          cooperate with a law enforcement body or regulatory agency, exercise
          or defend our legal rights, or disclose your information as evidence
          in litigation in which we are involved.
        </p>
        <p>
          Vital Interests. We may process your information where we believe it
          is necessary to protect your vital interests or the vital interests of
          a third party, such as situations involving potential threats to the
          safety of any person.
        </p>
        <p>If you are located in Canada, this section applies to you.</p>
        <p>&nbsp;</p>
        <p>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent). You can withdraw your consent at any
          time.
        </p>
        <p>&nbsp;</p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <p>
          If collection is clearly in the interests of an individual and consent
          cannot be obtained in a timely way
        </p>
        <p>For investigations and fraud detection and prevention</p>
        <p>For business transactions provided certain conditions are met</p>
        <p>
          If it is contained in a witness statement and the collection is
          necessary to assess, process, or settle an insurance claim
        </p>
        <p>
          For identifying injured, ill, or deceased persons and communicating
          with next of kin
        </p>
        <p>
          If we have reasonable grounds to believe an individual has been, is,
          or may be victim of financial abuse
        </p>
        <p>
          If it is reasonable to expect collection and use with consent would
          compromise the availability or the accuracy of the information and the
          collection is reasonable for purposes related to investigating a
          breach of an agreement or a contravention of the laws of Canada or a
          province
        </p>
        <p>
          If disclosure is required to comply with a subpoena, warrant, court
          order, or rules of the court relating to the production of records
        </p>
        <p>
          If it was produced by an individual in the course of their employment,
          business, or profession and the collection is consistent with the
          purposes for which the information was produced
        </p>
        <p>
          If the collection is solely for journalistic, artistic, or literary
          purposes
        </p>
        <p>
          If the information is publicly available and is specified by the
          regulations
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>
        <p>&nbsp;</p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <p>
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>&nbsp;</p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </p>
        <p>&nbsp;</p>
        <p>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p>&nbsp;</p>
        <p>
          To the extent these online tracking technologies are deemed to be a
          'sale'/'sharing' (which includes targeted advertising, as defined
          under the applicable laws) under applicable US state laws, you can opt
          out of these online tracking technologies by submitting a request as
          described below under section 'DO UNITED STATES RESIDENTS HAVE
          SPECIFIC PRIVACY RIGHTS?'
        </p>
        <p>&nbsp;</p>
        <p>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>
        <p>&nbsp;</p>
        <p>Google Analytics</p>
        <p>&nbsp;</p>
        <p>
          We may share your information with Google Analytics to track and
          analyse the use of the Services. The Google Analytics Advertising
          Features that we may use include: Google Analytics Demographics and
          Interests Reporting. To opt out of being tracked by Google Analytics
          across the Services, visit&nbsp;
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          . You can opt out of Google Analytics Advertising Features through Ads
          Settings and Ad Settings for mobile apps. Other opt out means
          include&nbsp;
          <a href="http://optout.networkadvertising.org/">
            http://optout.networkadvertising.org/
          </a>
          &nbsp;and&nbsp;
          <a href="http://www.networkadvertising.org/mobile-choice">
            http://www.networkadvertising.org/mobile-choice
          </a>
          . For more information on the privacy practices of Google, please
          visit the Google Privacy &amp; Terms page.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.
        </p>
        <p>&nbsp;</p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </p>
        <p>&nbsp;</p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this Privacy Notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We keep your information for as long as necessary to fulfil
          the purposes outlined in this Privacy Notice unless otherwise required
          by law.
        </p>
        <p>&nbsp;</p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this Privacy Notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>&nbsp;</p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We aim to protect your personal information through a system
          of organisational and technical security measures.
        </p>
        <p>&nbsp;</p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>&nbsp;</p>
        <p>
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at&nbsp;
          <a href="mailto:hello@madeitout.io">hello@madeitout.io</a>.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: Depending on your state of residence in the US or in some
          regions, such as the European Economic Area (EEA), United Kingdom
          (UK), Switzerland, and Canada, you have rights that allow you greater
          access to and control over your personal information. You may review,
          change, or terminate your account at any time, depending on your
          country, province, or state of residence.
        </p>
        <p>&nbsp;</p>
        <p>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your
          personal information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
          NOTICE?' below.
        </p>
        <p>&nbsp;</p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or UK data
          protection authority.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner.
        </p>
        <p>&nbsp;</p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
        </p>
        <p>&nbsp;</p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <p>&nbsp;</p>
        <p>
          Opting out of marketing and promotional communications: You can
          unsubscribe from our marketing and promotional communications at any
          time by clicking on the unsubscribe link in the emails that we send,
          or by contacting us using the details provided in the section 'HOW CAN
          YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed
          from the marketing lists. However, we may still communicate with you
          &mdash; for example, to send you service-related messages that are
          necessary for the administration and use of your account, to respond
          to service requests, or for other non-marketing purposes.
        </p>
        <p>&nbsp;</p>
        <p>Account Information</p>
        <p>&nbsp;</p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <p>Log in to your account settings and update your user account.</p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>&nbsp;</p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at&nbsp;
          <a href="mailto:hello@madeitout.io">hello@madeitout.io</a>.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this Privacy
          Notice.
        </p>
        <p>&nbsp;</p>
        <p>
          California law requires us to let you know how we respond to web
          browser DNT signals. Because there currently is not an industry or
          legal standard for recognising or honouring DNT signals, we do not
          respond to them at this time.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: If you are a resident of California, Colorado, Connecticut,
          Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire,
          New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have
          the right to request access to and receive details about the personal
          information we maintain about you and how we have processed it,
          correct inaccuracies, get a copy of, or delete your personal
          information. You may also have the right to withdraw your consent to
          our processing of your personal information. These rights may be
          limited in some circumstances by applicable law. More information is
          provided below.
        </p>
        <p>&nbsp;</p>
        <p>Categories of Personal Information We Collect</p>
        <p>&nbsp;</p>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <p>&nbsp;</p>
        <p>Category &nbsp; &nbsp;Examples &nbsp; &nbsp;Collected</p>
        <p>A. Identifiers</p>
        <p>
          Contact details, such as real name, alias, postal address, telephone
          or mobile contact number, unique personal identifier, online
          identifier, Internet Protocol address, email address, and account name
        </p>
        <p>&nbsp;</p>
        <p>YES</p>
        <p>&nbsp;</p>
        <p>
          B. Personal information as defined in the California Customer Records
          statute
        </p>
        <p>
          Name, contact information, education, employment, employment history,
          and financial information
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>
          C. Protected classification characteristics under state or federal law
        </p>
        <p>
          Gender, age, date of birth, race and ethnicity, national origin,
          marital status, and other demographic data
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>D. Commercial information</p>
        <p>
          Transaction information, purchase history, financial details, and
          payment information
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>E. Biometric information</p>
        <p>Fingerprints and voiceprints</p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>F. Internet or other similar network activity</p>
        <p>
          Browsing history, search history, online behaviour, interest data, and
          interactions with our and other websites, applications, systems, and
          advertisements
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>G. Geolocation data</p>
        <p>Device location</p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>H. Audio, electronic, sensory, or similar information</p>
        <p>
          Images and audio, video or call recordings created in connection with
          our business activities
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>I. Professional or employment-related information</p>
        <p>
          Business contact details in order to provide you our Services at a
          business level or job title, work history, and professional
          qualifications if you apply for a job with us
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>J. Education Information</p>
        <p>Student records and directory information</p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>K. Inferences drawn from collected personal information</p>
        <p>
          Inferences drawn from any of the collected personal information listed
          above to create a profile or summary about, for example, an
          individual&rsquo;s preferences and characteristics
        </p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>L. Sensitive personal Information &nbsp; &nbsp; &nbsp; &nbsp;</p>
        <p>&nbsp;</p>
        <p>NO</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <p>Receiving help through our customer support channels;</p>
        <p>Participation in customer surveys or contests; and</p>
        <p>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </p>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <p>Category A - As long as the user has an account with us</p>
        <p>Category H - Do not collect.</p>
        <p>Sources of Personal Information</p>
        <p>&nbsp;</p>
        <p>
          Learn more about the sources of personal information we collect in
          'WHAT INFORMATION DO WE COLLECT?'
        </p>
        <p>&nbsp;</p>
        <p>How We Use and Share Personal Information</p>
        <p>&nbsp;</p>
        <p>
          Learn about how we use your personal information in the section, 'HOW
          DO WE PROCESS YOUR INFORMATION?'
        </p>
        <p>&nbsp;</p>
        <p>Will your information be shared with anyone else?</p>
        <p>&nbsp;</p>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section, 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
        </p>
        <p>&nbsp;</p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be 'selling'
          of your personal information.
        </p>
        <p>&nbsp;</p>
        <p>
          We have not disclosed, sold, or shared any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. We will not sell or share personal information in
          the future belonging to website visitors, users, and other consumers.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Your Rights</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          You have rights under certain US state data protection laws. However,
          these rights are not absolute, and in certain cases, we may decline
          your request as permitted by law. These rights include:
        </p>
        <p>Right to know whether or not we are processing your personal data</p>
        <p>Right to access your personal data</p>
        <p>Right to correct inaccuracies in your personal data</p>
        <p>Right to request the deletion of your personal data</p>
        <p>
          Right to obtain a copy of the personal data you previously shared with
          us
        </p>
        <p>Right to non-discrimination for exercising your rights</p>
        <p>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising (or sharing as defined under
          California&rsquo;s privacy law), the sale of personal data, or
          profiling in furtherance of decisions that produce legal or similarly
          significant effects ('profiling')
        </p>
        <p>
          Depending upon the state where you live, you may also have the
          following rights:
        </p>
        <p>
          Right to obtain a list of the categories of third parties to which we
          have disclosed personal data (as permitted by applicable law,
          including California's and Delaware's privacy law)
        </p>
        <p>
          Right to obtain a list of specific third parties to which we have
          disclosed personal data (as permitted by applicable law, including
          Oregon&rsquo;s privacy law)
        </p>
        <p>
          Right to limit use and disclosure of sensitive personal data (as
          permitted by applicable law, including California&rsquo;s privacy law)
        </p>
        <p>
          Right to opt out of the collection of sensitive data and personal data
          collected through the operation of a voice or facial recognition
          feature (as permitted by applicable law, including Florida&rsquo;s
          privacy law)
        </p>
        <p>How to Exercise Your Rights</p>
        <p>&nbsp;</p>
        <p>
          To exercise these rights, you can contact us by submitting a data
          subject access request, by emailing us at&nbsp;
          <a href="mailto:hello@madeitout.io">hello@madeitout.io</a>, or by
          referring to the contact details at the bottom of this document.
        </p>
        <p>&nbsp;</p>
        <p>
          Under certain US state data protection laws, you can designate an
          authorised agent to make a request on your behalf. We may deny a
          request from an authorised agent that does not submit proof that they
          have been validly authorised to act on your behalf in accordance with
          applicable laws.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Request Verification</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. We will only use personal information provided in your
          request to verify your identity or authority to make the request.
          However, if we cannot verify your identity from the information
          already maintained by us, we may request that you provide additional
          information for the purposes of verifying your identity and for
          security or fraud-prevention purposes.
        </p>
        <p>&nbsp;</p>
        <p>
          If you submit the request through an authorised agent, we may need to
          collect additional information to verify your identity before
          processing your request and the agent will need to provide a written
          and signed permission from you to submit such request on your behalf.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Appeals</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Under certain US state data protection laws, if we decline to take
          action regarding your request, you may appeal our decision by emailing
          us at&nbsp;<a href="mailto:hello@madeitout.io">hello@madeitout.io</a>.
          We will inform you in writing of any action taken or not taken in
          response to the appeal, including a written explanation of the reasons
          for the decisions. If your appeal is denied, you may submit a
          complaint to your state attorney general.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>California 'Shine The Light' Law</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          California Civil Code Section 1798.83, also known as the 'Shine The
          Light' law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us by using the contact details provided in the section
          'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>13. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>&nbsp;</p>
        <p>
          We may update this Privacy Notice from time to time. The updated
          version will be indicated by an updated 'Revised' date at the top of
          this Privacy Notice. If we make material changes to this Privacy
          Notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this Privacy Notice frequently to be informed of how we
          are protecting your information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          If you have questions or comments about this notice, you may email us
          at&nbsp;<a href="mailto:hello@madeitout.io">hello@madeitout.io</a>
          &nbsp;or contact us by post at:
        </p>
        <p>&nbsp;</p>
        <p>Made It Out</p>
        <p>__________</p>
        <p>__________</p>
        <p>&nbsp;</p>
        <p>
          <strong>
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Based on the applicable laws of your country or state of residence in
          the US, you may have the right to request access to the personal
          information we collect from you, details about how we have processed
          it, correct inaccuracies, or delete your personal information. You may
          also have the right to withdraw your consent to our processing of your
          personal information. These rights may be limited in some
          circumstances by applicable law. To request to review, update, or
          delete your personal information, please fill out and submit a data
          subject access request.
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
