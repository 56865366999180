import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const ThankYou = () => {
  const location = useLocation();
  const { friendName, eventName, hostName } = location.state || {};

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col items-center justify-center mt-20 p-4">
        <h1 className="text-3xl font-bold mb-4">Thank You, {friendName}! 🎉</h1>
        <p className="text-xl mb-2 text-center">Your availability for <strong>{eventName}</strong> <br></br> has been saved.</p>
        <p className="text-lg font-light pt-10 text-center">{hostName} will be in touch 😄</p>
      </div>
    </div>
  );
};

export default ThankYou;