import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  CalendarIcon,
  UsersIcon,
  LinkIcon,
  BoltIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import Header from "./Header";
import IconBlocks from "./IconBlocks";
import TabsHome from "./TabsHome";
import Wiggle from "./Wiggle";
import Footer from "./Footer";

const LandingPage = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      localStorage.setItem("emailForLogin", email);
      window.location.href = "/signup";
    }
  };

  return (
    <div className="bg-white min-h-screen text-gray-900 font-inter">
      <Header />

      <section>
        <>
          {/* Hero */}
          <div className="relative overflow-hidden hero-pattern">
            {/* <div className='absolute top-14 left-10 text-xl -rotate-[35deg]'>😍</div> */}

            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-12 pb-8 border-b-1 z-10">
              {/* Announcement Banner */}
              <div className="flex justify-center">
                <a
                  className="inline-flex items-center gap-x-2 bg-white border border-gray-200 text-sm text-gray-800 p-1 ps-3 rounded-full transition hover:border-gray-300 focus:outline-none focus:border-gray-300"
                  href="#"
                >
                  ✨ Start Planning Events
                  <span className="py-1.5 px-2.5 inline-flex justify-center items-center gap-x-2 rounded-full bg-gray-50 font-semibold text-sm text-gray-600">
                    <svg
                      className="shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  </span>
                </a>
              </div>
              {/* End Announcement Banner */}
              {/* Title */}
              <div className="mt-5 max-w-2xl text-center mx-auto">
                <h1 className="text-[3.5rem] font-black font-normal mb-3 leading-[1.25em] hero-text">
                  turn group chat <Wiggle /> into <br />
                  <span className="font-semibold flex flex-row items-center justify-center">
                    real plans{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-12 ml-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                      />
                    </svg>
                  </span>
                </h1>
              </div>
              {/* End Title */}
              <div className="mt-5 max-w-3xl text-center mx-auto pb-5">
                <p className="text-lg text-gray-600">
                  Create & share events with friends to find their availability,
                  in minutes.
                </p>
              </div>
              {/* Buttons */}
              <form
                onSubmit={handleSubmit}
                className="flex justify-center flex-col sm:flex-row gap-4 mb-4 p-[1rem] pb-0 mb-0"
              >
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="px-4 py-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 w-full sm:w-1/3"
                />
                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="bg-black text-white font-semibold py-3 px-6 rounded-lg transition duration-300 hover:bg-gray-800 flex justify-center"
                  >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
</svg> */}

                    <span className="pr-2">Get Started</span>
                    <ArrowRightIcon className="size-6" />
                  </button>
                  <p className="text-center text-xs mb-4 mt-4">
                    100% free. Create an event in {"<"} 2 mins.
                  </p>
                </div>
              </form>

              <div className="mx-auto mt-4 max-w-xs md:flex md:max-w-lg md:items-center md:justify-center md:space-y-4 lg:mt-4 flex items-center justify-center">
                <div className="flex shrink-0 justify-center -space-x-3 overflow-hidden">
                  <img
                    alt="Ana"
                    src="./alyssa.webp"
                    className="inline-block h-10 w-10 rounded-full ring-2 ring-gray-100 object-contain"
                  />
                  <img
                    alt="Anthony"
                    src="./ben.webp"
                    className="inline-block h-10 w-10 rounded-full ring-2 ring-gray-100 object-cover"
                  />
                  <img
                    alt="Ayush"
                    src="./katy.webp"
                    className="inline-block h-10 w-10 rounded-full ring-2 ring-gray-100 object-cover object-top"
                  />
                </div>
                <p className="text-center text-base text-gray-900 md:mt-0 md:ml-4 p-2">
                  <div class="flex items-center justify-center pb-2">
                    <svg
                      class="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      class="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      class="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      class="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      class="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <span className="block text-sm">
                    Join{/* */}{" "}
                    <span className="font-semibold">150+ creators</span>{" "}
                  </span>
                </p>
              </div>

              {/* End Buttons */}
            </div>
          </div>
          {/* End Hero */}
        </>
      </section>
      <IconBlocks />
      <div className=" flex justify-center hero-pattern">
        <img src="./desktop3.webp" className="pb-0 sm:w-[20rem]"></img>
      </div>

      {/* <TabsHome /> */}
      {/* How It Works Section */}
      <section className="bg-gray-50 py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-3">How It Works</h2>
          <p className="text-md text-center text-gray-600 mb-8">
            Coordinate groups, manage events, and streamline your social life
            effortlessly.
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-12">
            <Step
              number={1}
              title="Sign Up"
              description="Create your account in seconds"
            />
            <Step
              number={2}
              title="Create Event"
              description="Set up your event details"
            />
            <Step
              number={3}
              title="Invite Friends"
              description="Share and start planning together"
            />
          </div>
        </div>
      </section>

      <section>
        <div className=" flex justify-center hero-pattern py-10">
          {/* NEW SECTION HJERE */}
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-black text-white py-24">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-black mb-6 font-normal">
            Ready to simplify your event planning?
          </h2>
          <Link
            to="/signup"
            className="bg-white text-black font-bold py-3 px-8 rounded-lg text-lg transition duration-300 hover:bg-gray-100"
          >
            Get Started Free
          </Link>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg text-center">
    <div className="mb-4 flex justify-center">{icon}</div>
    <h3 className="font-bold text-lg mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Step = ({ number, title, description }) => (
  <div className="text-center">
    <div className="w-16 h-16 bg-black text-white rounded-full flex items-center justify-center text-2xl font-bold mb-4 mx-auto">
      {number}
    </div>
    <h3 className="font-bold text-lg mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

export default LandingPage;
