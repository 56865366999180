import React, { useState, useEffect } from "react";
import {
  getAuth,
  updateEmail,
  sendPasswordResetEmail,
  updateProfile,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithPopup,
  signInWithCredential,
  unlink,
} from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";
import { initializeGoogleApi, getAccessToken } from "../googleCalendarService";

const MyAccount = ({ onProfileUpdate }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [email, setEmail] = useState(user?.email || "");
  const [displayName, setDisplayName] = useState(user?.displayName || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState("");
  const [profilePicture, setProfilePicture] = useState(user?.photoURL || "");
  const [googleLinked, setGoogleLinked] = useState(false);
  const [linkedGmailAccount, setLinkedGmailAccount] = useState("");

  useEffect(() => {
    if (user) {
      setEmail(user.email || "");
      setDisplayName(user.displayName || "");
      setProfilePicture(user.photoURL || "");
      checkGoogleLinkStatus();
    }
  }, [user]);

  // In your componen

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      if (user) {
        await updateProfile(user, { displayName });
        await updateEmail(user, email);

        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          displayName,
          email,
        });

        setMessage("Profile updated successfully!");
        onProfileUpdate({ displayName, photoURL: user.photoURL });
      }
    } catch (error) {
      setMessage("Error updating profile: " + error.message);
    }
    setIsUpdating(false);
  };

  const checkGoogleLinkStatus = async () => {
    if (!user) return;

    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();

    if (userData && userData.googleLinked) {
      setGoogleLinked(true);
      setLinkedGmailAccount(userData.googleEmail || "");
    } else {
      setGoogleLinked(false);
      setLinkedGmailAccount("");
    }
  };

  const handleLinkGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/calendar.events");

    try {
      const result = await linkWithPopup(auth.currentUser, provider);
      await initializeGoogleApi();
      const token = await getAccessToken();

      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        googleLinked: true,
        googleEmail: result.user.email,
      });

      setGoogleLinked(true);
      setLinkedGmailAccount(result.user.email);
      setMessage("Google account linked successfully!");
    } catch (error) {
      console.error("Error linking Google account:", error);
      if (error.code === "auth/credential-already-in-use") {
        setMessage(
          "This Google account is already linked to another user. Please use a different Google account or unlink it from the other account first."
        );
      } else {
        setMessage("Error linking Google account: " + error.message);
      }
    }
  };

  const handleUnlinkGoogle = async () => {
    try {
      await unlink(auth.currentUser, "google.com");

      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        googleLinked: false,
        googleEmail: null,
      });

      setGoogleLinked(false);
      setLinkedGmailAccount("");
      setMessage("Google account unlinked successfully!");
    } catch (error) {
      console.error("Error unlinking Google account:", error);
      setMessage("Error unlinking Google account: " + error.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent!");
    } catch (error) {
      setMessage("Error sending password reset email: " + error.message);
    }
  };

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    if (file && user) {
      try {
        setIsUpdating(true);
        setMessage("Uploading profile picture...");

        const storageRef = ref(storage, `profile_pictures/${user.uid}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        await updateProfile(user, { photoURL: downloadURL });

        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          photoURL: downloadURL,
        });

        setProfilePicture(downloadURL);
        setMessage("Profile picture updated successfully!");
        onProfileUpdate({
          displayName: user.displayName,
          photoURL: downloadURL,
        });
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        setMessage(`Error uploading profile picture: ${error.message}`);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  return (
    <div className="max-w-2xl mx-auto ml-0 p-6 bg-white rounded-lg">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">My Account</h2>

      {message && (
        <div className="mb-6 p-4 rounded-lg bg-blue-100 text-blue-700">
          {message}
        </div>
      )}

      <div className="space-y-6">
        {/* Profile Picture */}
        <div className="flex items-center space-x-6">
          <img
            src={profilePicture || "/default_pp2.jpg"}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
          />
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Profile Picture
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureUpload}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-black file:text-white
                hover:file:bg-blue-100"
            />
          </div>
        </div>

        {/* Display Name */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Display Name
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 h-[50px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#eee]"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>

        {/* Email */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            className="w-full px-3 h-[50px] py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#eee]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* Google Integration Status */}
        <div>
          {googleLinked ? (
            <div>
              <p className="text-green-600">
                Your account is linked with Google.
              </p>
              <p className="text-sm text-gray-600">
                Linked Gmail: {linkedGmailAccount}
              </p>
              <button
                onClick={handleUnlinkGoogle}
                className="mt-2 px-4 py-2 rounded-md bg-red-500 text-white text-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Unlink Google Account
              </button>
            </div>
          ) : (
            <div>
              <p className="text-red-600">
                Your account is not linked with Google.
              </p>
              <button
                onClick={handleLinkGoogle}
                className="mt-2 px-4 py-2 rounded-md bg-blue-500 text-white text-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Link Google Account
              </button>
            </div>
          )}
        </div>

        <div className="flex space-x-4">
          <button
            onClick={handleUpdate}
            disabled={isUpdating}
            className={`px-4 py-2 rounded-md bg-indigo-500 text-white text-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
              isUpdating ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isUpdating ? "Updating..." : "Update Profile"}
          </button>

          <button
            onClick={handlePasswordReset}
            className="px-4 py-2 pl-1 rounded-md bg-white text-black text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
