import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import Loader from './Loader'; // Import the custom Loader component

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) return <Loader />; // Use the Loader component while loading
  return user ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
