import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import Header from "./Header";

const Availability = () => {
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [friendName, setFriendName] = useState("");
  const [friendEmail, setFriendEmail] = useState(""); // Added state for email
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateCounts, setDateCounts] = useState({});
  const [hostDates, setHostDates] = useState([]);
  const [hostName, setHostName] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [timeframe, setTimeframe] = useState(null); // State for the timeframe (start and end dates)
  const navigate = useNavigate();

  const uuid = searchParams.get("uuid");
  const eventName = searchParams.get("event");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    dates: "",
  });

  const resetDates = () => {
    setSelectedDates([]);
  };
  // Utility function to check if two dates are equal (ignoring time)
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", dates: "" };

    if (!friendName.trim()) {
      newErrors.name = "Please enter your name";
      isValid = false;
    }

    if (!friendEmail.trim()) {
      newErrors.email = "Please enter your email";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(friendEmail)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (selectedDates.length === 0) {
      newErrors.dates = "Please select at least one date";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const selectEntireMonth = () => {
    const firstDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const lastDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );
    const newDates = [];

    for (let day = 1; day <= lastDay.getDate(); day++) {
      const date = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      if (
        isDateInHostRange(date) &&
        !selectedDates.some((selectedDate) => isSameDay(selectedDate, date))
      ) {
        newDates.push(date);
      }
    }

    setSelectedDates([...selectedDates, ...newDates]); // Merge with existing selected dates
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const docRef = doc(db, "users", uuid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          const event = userData.events[eventName];

          if (event) {
            // Set host details
            setHostName(event.name);
            setHostDates(event.selectedDates);

            // Set event timeframe
            const timeframe = event.timeframe;
            if (timeframe) {
              const start = new Date(timeframe.startDate);
              const end = new Date(timeframe.endDate);
              setCurrentMonth(new Date(timeframe.startDate));
              setTimeframe({ start, end });
            }

            // Filter out email addresses from the friends data
            const filteredFriends = Object.keys(event.friends).map(
              (friendName) => {
                return {
                  name: friendName,
                  dates: event.friends[friendName].dates, // Only include dates
                };
              }
            );

            setEventDetails({
              ...event,
              friends: filteredFriends, // Set filtered friends data
            });

            const allSelectedDates = calculateDateCounts(event);
            setDateCounts(allSelectedDates);
          } else {
            setError("Event not found");
          }
        } else {
          setError("No such user found");
        }
      } catch (err) {
        setError("Failed to fetch event data");
        console.error("Error fetching event:", err);
      } finally {
        setLoading(false);
      }
    };

    if (uuid && eventName) {
      fetchEventDetails();
    } else {
      setError("Invalid URL parameters");
      setLoading(false);
    }
  }, [uuid, eventName]);

  const calculateDateCounts = (event) => {
    const dateMap = {};

    event.selectedDates.forEach((date) => {
      const dateString = new Date(date.seconds * 1000).toLocaleDateString();
      dateMap[dateString] = (dateMap[dateString] || 0) + 1;
    });

    if (event.friends) {
      Object.values(event.friends).forEach((friend) => {
        friend.dates.forEach((date) => {
          const dateString = new Date(date.seconds * 1000).toLocaleDateString();
          dateMap[dateString] = (dateMap[dateString] || 0) + 1;
        });
      });
    }

    return dateMap;
  };

  const isDateInHostRange = (date) => {
    if (!timeframe) return false;

    const { start, end } = timeframe;
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    return date >= start && date <= end;
  };

  const handleDateSelection = (date) => {
    if (selectedDates.some((selectedDate) => isSameDay(selectedDate, date))) {
      setSelectedDates(
        selectedDates.filter((selectedDate) => !isSameDay(selectedDate, date))
      );
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const getDateColor = (count) => {
    if (count === 1) return "bg-heat-1";
    if (count === 2) return "bg-heat-2";
    if (count === 3) return "bg-heat-3";
    if (count === 4) return "bg-heat-4";
    if (count >= 5) return "bg-heat-5";
    return "";
  };

  const highlightSelectedDates = (date) => {
    const dateString = date.toLocaleDateString();
    const userSelected = selectedDates.some(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );
    if (userSelected) return "bg-blue-500 text-white";
    const count = dateCounts[dateString] || 0;
    return getDateColor(count);
  };

  const generateDatesForMonth = () => {
    const firstDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const lastDay = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );
    const dates = [];

    for (let day = 1; day <= lastDay.getDate(); day++) {
      dates.push(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
      );
    }

    return dates;
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const goToNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const formatDate = (date) => {
    if (date instanceof Timestamp) {
      date = date.toDate();
    } else if (!(date instanceof Date)) {
      date = new Date(date);
    }

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date.getDate()} ${monthNames[date.getMonth()]}`;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const docRef = doc(db, "users", uuid);
        const friendData = {
          name: friendName,
          email: friendEmail,
          dates: selectedDates.map((date) => Timestamp.fromDate(date)),
        };

        const docSnap = await getDoc(docRef);

        await updateDoc(docRef, {
          [`events.${eventName}.friends.${friendName}`]: friendData,
        });

        navigate("/thank-you", {
          state: {
            friendName,
            eventName,
            hostName,
          },
        });
      } catch (err) {
        console.error("Failed to update Firestore:", err);
        setErrors({
          ...errors,
          general: `Failed to save data: ${err.message}`,
        });
      }
    }
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );
  const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;

  const datesInMonth = [];
  let date = new Date(firstDayOfMonth);
  date.setDate(date.getDate() - firstDayOfWeek);

  while (datesInMonth.length < 42) {
    datesInMonth.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header /> {/* Header takes natural height */}
      <div className="flex min-h-full flex-col justify-center py-12 pt-0 sm:px-6 lg:px-8 hero-pattern">
        <div className="sm:mx-auto sm:w-full sm:max-w-md bg-gradient-to-t from-white to-transparent pt-12">
          <h2 className="mt-6 text-center text-4xl leading-9 text-gray-900 mx-5 leading-[1.25em] text-[2.5rem]">
            <strong className="font-bold">{hostName}</strong>
            <br /> <span className="italic">has invited you to</span> <br />
            {eventName}!
          </h2>
          {/* <p className="mt-2 text-center text-sm leading-6 text-gray-500">
          Please select dates you are free!
        </p> */}

          <h3 className="text-xl font-light mt-6 text-center text-gray-900 pb-4">
            When are you free between <br />
            <strong className="font-semibold">
              {formatDate(timeframe.start)}
            </strong>{" "}
            &{" "}
            <strong className="font-semibold">
              {" "}
              {formatDate(timeframe.end)}
            </strong>
            ?
          </h3>
          {/* <p className="mt-4 text-center text-sm text-indigo-600 font-normal">
  Host's suggested dates: <strong className='font-semibold'>{hostDates.map(date => formatDate(date)).join(', ')}</strong>
</p> */}
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white px-6 py-4 sm:rounded-lg sm:px-12 ">
            <div className="mb-10">
              <input
                type="text"
                placeholder="Your Name"
                value={friendName}
                onChange={(e) => setFriendName(e.target.value)}
                className={`block w-full rounded-md border-0 py-1.5 h-[50px] text-gray-900 shadow-sm ring-1 ring-inset ${
                  errors.name ? "ring-red-500" : "ring-gray-300"
                } focus:ring-2 focus:ring-inset focus:ring-[#dfdfdf] sm:text-sm sm:leading-6`}
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-500">{errors.name}</p>
              )}

              <input
                type="email"
                placeholder="Your Email"
                value={friendEmail}
                onChange={(e) => setFriendEmail(e.target.value)}
                className={`block w-full rounded-md border-0 py-1.5 h-[50px] text-gray-900 shadow-sm ring-1 ring-inset ${
                  errors.email ? "ring-red-500" : "ring-gray-300"
                } focus:ring-2 focus:ring-inset focus:ring-[#dfdfdf] sm:text-sm sm:leading-6 mt-4`}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>
            <div className="flex justify-between items-center mb-4">
              <button
                onClick={goToPreviousMonth}
                className="bg-white px-3 py-1 rounded-md text-gray-700"
              >
                Previous
              </button>
              <h3 className="text-lg font-semibold">
                {currentMonth.toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </h3>
              <button
                onClick={goToNextMonth}
                className="bg-white px-3 py-1 rounded-md text-gray-700"
              >
                Next
              </button>
            </div>

            <div className="grid grid-cols-7 gap-2 mb-4">
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                <div key={day} className="text-center font-bold text-gray-600">
                  {day}
                </div>
              ))}
            </div>

            <div className="grid grid-cols-7 gap-2">
              {datesInMonth.map((date) => {
                const isCurrentMonth =
                  date.getMonth() === currentMonth.getMonth();
                const isSelected = selectedDates.some(
                  (selectedDate) =>
                    selectedDate.getDate() === date.getDate() &&
                    selectedDate.getMonth() === date.getMonth() &&
                    selectedDate.getFullYear() === date.getFullYear()
                );

                // Check if the date is within the host's suggested range
                const isWithinHostRange = isDateInHostRange(date);

                return (
                  <div
                    key={date.getTime()}
                    className={`h-10 flex justify-center items-center cursor-pointer w-full rounded ${highlightSelectedDates(
                      date
                    )} ${isSelected ? "bg-blue-500 text-white" : "bg-white"} ${
                      !isCurrentMonth || !isWithinHostRange
                        ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() =>
                      isCurrentMonth &&
                      isWithinHostRange &&
                      handleDateSelection(date)
                    } // Only allow selection if within host's range
                  >
                    {date.getDate()}
                  </div>
                );
              })}
            </div>
            <div className="flex ">
              {/* New Select Entire Month button */}
              <button
                onClick={selectEntireMonth}
                className="mt-4 mr-2 w-full h-[50px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-black border shadow-sm"
              >
                Select Entire Month
              </button>

              <button
                onClick={resetDates}
                className="mt-4 ml-2 w-full h-[50px] rounded-md bg-white px-3 py-2 text-sm font-semibold text-black border shadow-sm"
              >
                Clear Dates
              </button>
            </div>
            <button
              onClick={handleSubmit}
              className="mt-4 w-full h-[50px] rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#555] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Submit Dates
            </button>

            {errors.dates && (
              <p className="mt-2 text-sm text-red-500 text-center">
                {errors.dates}
              </p>
            )}
            {errors.general && (
              <p className="mt-2 text-sm text-red-500">{errors.general}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
