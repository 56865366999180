import React from 'react';

const WigglingSpan = () => {
  return (
    <span 
      className="italic bg-[#ff5656] text-white px-3 rounded-xl animate-wiggle"
      style={{
        display: 'inline-block',
        animation: 'wiggle-and-pause 2s ease-in-out infinite'
      }}
    >
      ideas
    </span>
  );
};

const keyframes = `
  @keyframes wiggle-and-pause {
    0%, 20% { transform: rotate(0deg); }
    4% { transform: rotate(-5deg); }
    8% { transform: rotate(0deg); }
    12% { transform: rotate(5deg); }
    16% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
  }
`;

export default () => (
  <>
    <style>{keyframes}</style>
    <WigglingSpan />
  </>
);