import React, { useState, useEffect, useCallback } from "react";
import {
  createEvent,
  getAccessToken,
  checkIfTokenExists,
  initializeGoogleApi,
} from "../googleCalendarService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const pastelColors = [
  "bg-pink-100",
  "bg-blue-100",
  "bg-green-100",
  "bg-yellow-100",
  "bg-purple-100",
  "bg-indigo-100",
  "bg-red-100",
  "bg-orange-100",
];

const getColorForFriend = (index) => pastelColors[index % pastelColors.length];

// Utility function to get the initials of a name
const getInitials = (name) =>
  name
    .split(" ")
    .map((part) => part[0].toUpperCase())
    .join("");

const CalendarWithInvitees = ({
  invitees,
  hostDates,
  hostName,
  hostEmail,
  eventName,
  monthStart,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDayInvitees, setSelectedDayInvitees] = useState(null);
  const [isDayModalOpen, setIsDayModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isGoogleApiInitialized, setIsGoogleApiInitialized] = useState(false);
  const [isGoogleLinked, setIsGoogleLinked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [user, setUser] = useState(null);

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfWeek = (firstDayOfMonth.getDay() + 6) % 7;
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const [isLoading, setIsLoading] = useState(false);
  const inviteeDates = {};
  const [accessToken, setAccessToken] = useState(null);

  const [isGoogleApiReady, setIsGoogleApiReady] = useState(false);

  useEffect(() => {
    const initApi = async () => {
      try {
        await initializeGoogleApi();
        setIsGoogleApiReady(true);
      } catch (error) {
        console.error("Failed to initialize Google API:", error);
      }
    };
    initApi();
  }, []);

  useEffect(() => {
    setCurrentDate(
      new Date(
        new Date(monthStart).getFullYear(),
        new Date(monthStart).getMonth()
      )
    );
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const googleLinkedStatus = userDoc.data()?.googleLinked || false;
        setIsGoogleLinked(googleLinkedStatus);
      } else {
        setIsGoogleLinked(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const sendGoogleCalendarInvite = useCallback(async () => {
    setErrorMessage("");
    setSuccessMessage("");
    setEventLink("");
    setIsLoading(true);

    if (!isGoogleLinked) {
      setErrorMessage(
        "Please link your Google account in My Account to send calendar invites."
      );
      setIsLoading(false);
      return;
    }

    if (!isGoogleApiReady) {
      // setErrorMessage("Google API is not ready. Please try again in a moment.");
      setIsLoading(false);
      return;
    }

    try {
      const token = await getAccessToken();

      const validAttendees = selectedDayInvitees
        .filter((invitee) => {
          if (invitee.email === "You") {
            return validateEmail(hostEmail);
          }
          return validateEmail(invitee.email);
        })
        .map((invitee) => ({
          email: invitee.email === "You" ? hostEmail : invitee.email,
        }));

      if (validAttendees.length === 0) {
        setErrorMessage(
          "No valid attendee emails found. Please check the email addresses and try again."
        );
        setIsLoading(false);
        return;
      }

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const event = {
        summary: eventName || "Event from Made It Out",
        description: `This is an event created from Made It Out app: ${eventName}`,
        start: {
          date: new Date(currentYear, currentMonth, selectedDay, 10, 0, 0)
            .toISOString()
            .split("T")[0],

          timeZone: userTimezone,
        },
        end: {
          date: new Date(currentYear, currentMonth, selectedDay, 12, 0, 0)
            .toISOString()
            .split("T")[0],
          timeZone: userTimezone,
        },
        attendees: validAttendees,
      };

      console.log("Attempting to create event:", event);
      const response = await createEvent(event);
      console.log("Event created: %s", response.result.htmlLink);

      setSuccessMessage("Google Calendar invite sent successfully!");
      setEventLink(response.result.htmlLink);
    } catch (error) {
      console.error("Error sending calendar invite:", error);
      setErrorMessage(
        "Failed to send calendar invite. Please try again or relink your Google account in My Account."
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    isGoogleLinked,
    selectedDayInvitees,
    eventName,
    currentYear,
    currentMonth,
    selectedDay,
    hostEmail,
  ]);
  // Adding invitees' dates to inviteeDates
  Object.entries(invitees).forEach(([friendName, friendDetails], index) => {
    friendDetails.dates.forEach((date) => {
      const dateObj = new Date(date.seconds * 1000);
      if (
        dateObj.getMonth() === currentMonth &&
        dateObj.getFullYear() === currentYear
      ) {
        const day = dateObj.getDate();
        if (!inviteeDates[day]) inviteeDates[day] = [];
        inviteeDates[day].push({
          name: friendName,
          email: friendDetails.email,
          color: getColorForFriend(index),
          dates: friendDetails.dates,
        });
      }
    });
  });

  // Add host details to the inviteeDates (host is the event creator)
  const hostDateEntries = hostDates || [];
  hostDateEntries.forEach((date) => {
    const dateObj = new Date(date.seconds * 1000);
    if (
      dateObj.getMonth() === currentMonth &&
      dateObj.getFullYear() === currentYear
    ) {
      const day = dateObj.getDate();
      if (!inviteeDates[day]) inviteeDates[day] = [];
      // Ensuring host email is passed correctly
      inviteeDates[day].push({
        name: hostName || "Host",
        email: hostEmail || "You",
        color: "bg-green-100",
        dates: hostDates,
      });
    }
  });

  const goToNextMonth = () => {
    const newDate = new Date(currentYear, currentMonth + 1);
    setCurrentDate(newDate);
  };

  const goToPreviousMonth = () => {
    const newDate = new Date(currentYear, currentMonth - 1);
    setCurrentDate(newDate);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSelectedDayInvitees(inviteeDates[day] || []);
    setIsDayModalOpen(true);
  };

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      {/* Month Navigation */}
      <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime={`${currentYear}-${currentMonth + 1}`}>
            {currentDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </time>
        </h1>
        <div className="flex items-center">
          <button
            type="button"
            onClick={goToPreviousMonth}
            className="flex h-9 w-12 items-center justify-center rounded-l-md border border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative"
          >
            <span className="sr-only">Previous month</span>
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 20 20"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={goToNextMonth}
            className="flex h-9 w-12 items-center justify-center rounded-r-md border border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative"
          >
            <span className="sr-only">Next month</span>
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 20 20"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </header>

      <div className="grid grid-cols-7 sm:gap-2 sm:p-4">
        {/* Render days of the week */}
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <div
            key={day}
            className="text-center font-semibold text-gray-600 p-4 sm:p-0"
          >
            {day}
          </div>
        ))}

        {/* Render empty boxes for previous month's days */}
        {Array.from({ length: firstDayOfWeek }).map((_, index) => (
          <div
            key={index}
            className="bg-[#fbfbfb] text-gray-400 border border-gray-200 sm:rounded-lg w-full h-24 sm:border-t-1 sm:border-r-1 border-t-0 border-r-0"
          />
        ))}

        {/* Render days of the current month */}
        {Array.from({ length: daysInMonth }, (_, index) => {
          const day = index + 1;
          const inviteesForDay = inviteeDates[day] || [];

          return (
            <div
              key={day}
              className={`relative bg-white border border-gray-200 sm:rounded-lg w-full h-24 flex flex-col justify-start items-center p-1 sm:border-t-1 sm:border-r-1 border-t-0 border-r-0 cursor-pointer`}
              onClick={() => handleDayClick(day)}
            >
              <time
                dateTime={`${currentYear}-${currentMonth + 1}-${day}`}
                className="text-sm font-semibold"
              >
                {day}
              </time>

              {/* Display initials of invitees and host */}
              <div className="mt-1 flex flex-wrap items-center space-y-1">
                {inviteesForDay.slice(0, 2).map((invitee, i) => (
                  <div
                    key={i}
                    className={`sm:w-6 sm:h-6 w-4 h-4 rounded-full ${invitee.color} flex items-center justify-center sm:text-xs text-[0.5rem] font-medium text-gray-800 mr-1 !mt-0`}
                  >
                    {getInitials(invitee.name)}
                  </div>
                ))}

                {/* Show +n if more invitees available */}
                {inviteesForDay.length > 3 && (
                  <div className="sm:w-6 sm:h-6 w-4 h-4  rounded-full bg-gray-200 flex items-center justify-center sm:text-xs text-[0.5rem] font-medium text-gray-600 mr-1 sm:!mt-0 mt-1">
                    +{inviteesForDay.length - 3}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Day Modal */}
      {isDayModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            {/* Close Button (X) at top right */}
            <button
              onClick={() => {
                setIsDayModalOpen(false);
                setErrorMessage("");
                setSuccessMessage("");
              }}
              className="absolute top-2 right-2 bg-transparent text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <h2 className="text-xl font-semibold mb-4">
              Invitees for {selectedDay}{" "}
              {currentDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </h2>
            <ul>
              {selectedDayInvitees.map((invitee, i) => (
                <li key={i} className="mb-2">
                  <div className="flex flex-row items-center">
                    <div
                      className={
                        "rounded-2xl mr-2 justify-center w-5 h-5 " +
                        invitee.color
                      }
                    ></div>
                    <strong>{invitee.name}</strong> {" - "}(
                    {invitee.email || "No email"})<br />
                  </div>

                  {/* Dates: {invitee.dates.map((date, j) => new Date(date.seconds * 1000).toLocaleDateString()).join(', ')} */}
                </li>
              ))}
            </ul>
            <p className="py-4">
              Send Google Calendar invites for {eventName || "this event"}:
            </p>
            {errorMessage && (
              <div className="text-red-500 mt-2 mb-2">{errorMessage}</div>
            )}

            {successMessage && (
              <div className="text-green-500 mt-2 mb-2">
                {successMessage}
                {eventLink && (
                  <div className="mt-2">
                    <a
                      href={eventLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      View Event
                    </a>
                  </div>
                )}
              </div>
            )}
            {isGoogleLinked ? (
              <button
                onClick={sendGoogleCalendarInvite}
                className="btn btn-primary mt-4 bg-black text-white p-4 py-2 rounded-lg w-full"
                disabled={isLoading || !isGoogleApiReady}
              >
                {isLoading
                  ? "Sending..."
                  : !isGoogleApiReady
                  ? "Loading..."
                  : "Send Google Calendar Invite"}
              </button>
            ) : (
              <p className="mt-4 text-red-500">
                Please link your Google account in My Account to send calendar
                invites.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarWithInvitees;
