import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  linkWithCredential,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { GoogleLogin } from "@react-oauth/google"; // Google Login
import { db } from "../firebase"; // Firestore initialization
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import Header from "./Header";
import { FcGoogle } from "react-icons/fc";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const [errorMessage, setErrorMessage] = useState("");

  // Retrieve email from localStorage on component mount
  useEffect(() => {
    const storedEmail = localStorage.getItem("emailForLogin");
    if (storedEmail) {
      setEmail(storedEmail); // Prefill the email field
    }
  }, []);

  // Handle email/password signup
  const handleSignup = async () => {
    const uuid = uuidv4();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        uuid: uuid,
        events: [], // Future events will be stored here
      });

      navigate("/verify"); // Redirect to /create after signup
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setErrorMessage("This email is already in use.");
          break;
        case "auth/invalid-email":
          setErrorMessage("Invalid email address.");
          break;
        case "auth/weak-password":
          setErrorMessage("Password is too weak.");
          break;
        default:
          setErrorMessage("Failed to sign up. Please try again.");
      }
      console.error("Error creating user: ", error);
    }
  };

  // Handle Google Signup and link account if needed
  const handleGoogleSignupSuccess = async (credentialResponse) => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          uid: user.uid,
          name: user.displayName,
          events: [],
          googleLinked: true, // Add this line
          googleEmail: user.email, // Add this line
        });
        console.log("New user document created in Firestore with Google link");
      } else {
        // If the document exists, update it to ensure Google is linked
        await updateDoc(userDocRef, {
          googleLinked: true,
          googleEmail: user.email,
        });
        console.log("Existing user document updated with Google link");
      }

      console.log("Signed up with Google successfully!");
      navigate("/create");
    } catch (error) {
      setErrorMessage("Google signup failed. Please try again.");
      console.error("Error during Google signup:", error);
    }
  };
  return (
    <div className="flex flex-col min-h-screen">
      <Header /> {/* Header takes natural height */}
      {/* The signup form takes up the remaining space */}
      <div className="flex-grow flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-[#f8f8f8]">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {errorMessage && (
              <div className="text-red-500 text-sm mb-4">{errorMessage}</div>
            )}

            <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleSignup}
                  className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#555] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up
                </button>
              </div>
            </form>
            <div className="mt-5">
              {/* <GoogleLogin
                onSuccess={handleGoogleSignupSuccess}
                onError={() => console.log("Google Signup Failed")}
                text={"signup_with"}
              /> */}
              <button
                onClick={handleGoogleSignupSuccess}
                className="flex items-center h-[50px] justify-center w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <FcGoogle className="mr-3 size-5" />
                Sign up with Google
              </button>
            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
              Already have an account?{" "}
              <button
                onClick={() => navigate("/login")}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Log in
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
