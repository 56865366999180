import React from "react";
import {
  HomeIcon,
  CalendarIcon,
  UsersIcon,
  UserIcon,
  CalendarDaysIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";

const Sidebar = ({
  activeView,
  onShowEvents,
  onShowDashboard,
  toggleSidebar,
  onShowMyAccount,
  userPhotoURL,
  userName,
}) => {
  return (
    <div className="layout-content-container p-4 flex flex-col w-full md:w-80 bg-white h-full z-50 transition-transform transform mt-16 md:mt-0">
      <div className="flex flex-col gap-4">
        <div className="flex gap-3">
          <div
            className="bg-center bg-no-repeat aspect-square bg-cover rounded-full size-10 bg-[#e2e2e2]"
            style={{
              backgroundImage: `url(${userPhotoURL || "/default_pp2.jpg"})`,
            }}
          ></div>
          <div className="flex flex-col">
            <h1 className="text-black text-base font-medium leading-normal">
              {userName || "Made It Out"}
            </h1>
            <p className="text-[#6B6B6B] text-sm font-normal leading-normal">
              Made It Out
            </p>
          </div>
        </div>

        {/* Rest of the sidebar items remain the same */}
        <div className="flex flex-col gap-2">
          {/* Dashboard */}
          <div
            className={`flex items-center gap-3 px-3 py-2 rounded-full cursor-pointer ${
              activeView === "dashboard" ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
            onClick={() => {
              onShowDashboard();
              toggleSidebar();
            }}
          >
            <HomeIcon className="w-6 h-6 text-black" />
            <p className="text-black text-sm font-medium leading-normal">
              Dashboard
            </p>
          </div>

          {/* Events */}
          <div
            className={`flex items-center gap-3 px-3 py-2 rounded-full cursor-pointer ${
              activeView === "events" ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
            onClick={() => {
              onShowEvents();
              toggleSidebar();
            }}
          >
            <CalendarIcon className="w-6 h-6 text-black" />
            <p className="text-black text-sm font-medium leading-normal">
              Events
            </p>
          </div>

          {/* My Calendar (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <CalendarDaysIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">
              Calendar (Coming soon)
            </p>
          </div>

          {/* Ideas (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <LightBulbIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">
              Ideas (Coming soon)
            </p>
          </div>

          {/* Friends (Disabled) */}
          <div className="flex items-center gap-3 px-3 py-2 rounded-full opacity-50 cursor-not-allowed pointer-events-none">
            <UsersIcon className="w-6 h-6 text-gray-400" />
            <p className="text-gray-400 text-sm font-medium leading-normal">
              Friends (Coming soon)
            </p>
          </div>

          {/* My Account */}
          <div
            className={`flex items-center gap-3 px-3 py-2 rounded-full cursor-pointer ${
              activeView === "myAccount" ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
            onClick={() => {
              onShowMyAccount();
              toggleSidebar();
            }}
          >
            <UserIcon className="w-6 h-6 text-black" />
            <p className="text-black text-sm font-medium leading-normal">
              My Account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
