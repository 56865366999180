const API_KEY = "AIzaSyAXGcsG5QlLVsPFRN-49hVqQrXm6FXYcEw";
const CLIENT_ID =
  "1068382945772-ris18074tp7jhp2aa2flq6uv3agn6luu.apps.googleusercontent.com";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const SCOPES = ["https://www.googleapis.com/auth/calendar.events"];

let tokenClient;
let gapiInited = false;
let gisInited = false;

export function initializeGoogleApi() {
  return new Promise((resolve, reject) => {
    const initializeGapiClient = async () => {
      try {
        console.log("Initializing GAPI client...");
        await window.gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: DISCOVERY_DOCS,
        });
        gapiInited = true;
        console.log("Google API Client initialized");
        maybeResolve();
      } catch (error) {
        console.error("Error initializing Google API Client:", error);
        reject(new Error("Failed to initialize GAPI client"));
      }
    };

    const loadGisScript = () => {
      console.log("Loading GIS script...");
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        console.log("GIS script loaded");
        gisInited = true;
        maybeResolve();
      };
      script.onerror = (error) => {
        console.error("Error loading GIS script:", error);
        reject(new Error("Failed to load GIS script"));
      };
      document.body.appendChild(script);
    };

    const maybeResolve = () => {
      if (gapiInited && gisInited) {
        console.log("Both GAPI and GIS initialized, setting up token client");
        tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES.join(" "),
          callback: (response) => {
            console.log("Token callback received:", response);
          },
        });
        resolve();
      } else {
        console.log(
          "API initialization not complete. gapiInited:",
          gapiInited,
          ", gisInited:",
          gisInited
        );
      }
    };

    // Start initialization
    if (window.gapi) {
      window.gapi.load("client", initializeGapiClient);
    } else {
      reject(new Error("GAPI not found. Make sure the script is loaded."));
    }
    loadGisScript();
  });
}

export const getAccessToken = async () => {
  return new Promise((resolve, reject) => {
    if (!tokenClient) {
      reject(
        new Error(
          "Token client not initialized. Call initializeGoogleApi first."
        )
      );
      return;
    }

    if (!window.gapi) {
      reject(
        new Error(
          "Google API (gapi) not found. Make sure the script is loaded."
        )
      );
      return;
    }

    if (!window.gapi.client) {
      reject(
        new Error(
          "Google API client not initialized. Call initializeGoogleApi first."
        )
      );
      return;
    }

    tokenClient.callback = (response) => {
      if (response.error !== undefined) {
        reject(new Error(response.error));
      } else {
        resolve(response.access_token);
      }
    };

    try {
      if (window.gapi.client.getToken() === null) {
        tokenClient.requestAccessToken({ prompt: "consent" });
      } else {
        tokenClient.requestAccessToken({ prompt: "" });
      }
    } catch (error) {
      reject(new Error(`Error requesting access token: ${error.message}`));
    }
  });
};

export const createEvent = async (event) => {
  if (!window.gapi || !window.gapi.client) {
    throw new Error("Google API not fully loaded");
  }

  if (!window.gapi.client.getToken()) {
    throw new Error("Not authenticated. Call getAccessToken first.");
  }

  try {
    const response = await window.gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
    });
    return response;
  } catch (error) {
    console.error("Error creating event:", error);
    if (error.result && error.result.error) {
      throw new Error(error.result.error.message);
    } else if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred while creating the event");
    }
  }
};

export const checkGoogleLinkStatus = async () => {
  try {
    const token = await getAccessToken();
    return !!token;
  } catch (error) {
    console.error("Error checking Google link status:", error);
    return false;
  }
};
