import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-8">
          <div>
            <h2 className="text-white mb-4 text-lg font-semibold leading-tight tracking-[-0.015em] italic">
              {"💭" + " made it out"}
            </h2>
            <p className="text-sm text-gray-400">
              Simplifying event planning for everyone.
            </p>
          </div>
          {/* <div>
            <h3 className="font-bold mb-4">Product</h3>
            <ul className="text-sm text-gray-400">
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  Features
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  Pricing
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  FAQ
                </a>
              </li>
            </ul>
          </div> */}
          {/* <div>
            <h3 className="font-bold mb-4">Company</h3>
            <ul className="text-sm text-gray-400">
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  About Us
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  Careers
                </a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:text-white">
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
          <div>
            <h3 className="font-bold mb-4">Legal</h3>
            <ul className="text-sm text-gray-400">
              <li className="mb-2">
                <a href="/privacy-policy" className="hover:text-white">
                  Privacy Policy
                </a>
              </li>
              {/* <li className="mb-2">
                <a href="#" className="hover:text-white">
                  Terms of Service
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-sm text-gray-400">
          &copy; {new Date().getFullYear()} Made It Out. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
