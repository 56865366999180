import React, { useState, useEffect } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const Verify = () => {
  const [message, setMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  // Function to resend verification email
  const handleResendVerification = async () => {
    if (user) {
      setLoading(true);
      try {
        await sendEmailVerification(user);
        setResendMessage("A new verification email has been sent.");
      } catch (error) {
        console.error("Error resending verification email:", error);
        setResendMessage(
          "Failed to resend verification email. Please try again."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user && !user.emailVerified) {
      const sendVerification = async () => {
        setLoading(true);
        try {
          await sendEmailVerification(user);
          setResendMessage("A verification email has been sent.");
        } catch (error) {
          console.error("Error sending verification email:", error);
          setResendMessage(
            "Failed to send verification email. Please try again."
          );
        } finally {
          setLoading(false);
        }
      };
      sendVerification();
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (user) {
        await user.reload(); // Reloads the user data from Firebase
        if (user.emailVerified) {
          setIsEmailVerified(true);
          clearInterval(interval); // Stop the interval when email is verified
          navigate("/create"); // Redirect to /create when email is verified
        }
      }
    }, 3000); // Check every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [user, navigate]);
  // Check if user is logged in

  if (!user) {
    navigate("/login"); // Redirect to login if no user is logged in
    return null;
  }

  return (
    <div className="min-h-screen">
      {/* <Header /> */}

      <div className="flex flex-col items-center justify-center pt-20 bg-gray-100">
        <div className="bg-white shadow-md rounded-lg p-8">
          <h2 className="text-2xl font-bold text-center mb-4">
            Verify Your Email
          </h2>
          {!isEmailVerified ? (
            <>
              <p className="text-gray-700 text-center mb-6">
                We've sent an email to <strong>{user.email}</strong> with a link
                to verify your account. Please check your inbox (and spam
                folder).
              </p>
              <div className="flex justify-center">
                <button
                  onClick={handleResendVerification}
                  disabled={loading}
                  className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                >
                  {loading ? "Resending..." : "Resend Verification Email"}
                </button>
              </div>
              {resendMessage && (
                <p className="text-green-500 text-center mt-4">
                  {resendMessage}
                </p>
              )}
            </>
          ) : (
            <p className="text-green-500 text-center">
              Your email has been verified! Redirecting...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verify;
