import React, { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Header from "./Header";
import Sidebar from "./Sidebar";
import EventList from "./EventList";
import EventDetails from "./EventDetails";
import CreateEvent from "./CreateEvent";
import MyAccount from "./MyAccount";
import FeedbackForm from "./FeedbackForm";

const Dashboard = () => {
  const [events, setEvents] = useState({});
  const [expandedEvent, setExpandedEvent] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [activeView, setActiveView] = useState("dashboard");
  const [friendsInvited, setFriendsInvited] = useState(0);
  const [userInfo, setUserInfo] = useState(null);

  const auth = getAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserDataAndEvents = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEvents(data.events || {});

          let totalInvitees = 0;
          Object.values(data.events || {}).forEach((event) => {
            totalInvitees += Object.keys(event.friends || {}).length;
          });
          setFriendsInvited(totalInvitees);

          // Set user info from Firestore data
          setUserInfo({
            displayName: data.displayName || user.displayName,
            photoURL: data.photoURL || user.photoURL,
          });
        }
      }
    };
    fetchUserDataAndEvents();
  }, []);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const toggleEvent = (eventName) => {
    setExpandedEvent(expandedEvent === eventName ? null : eventName);
  };

  const showCreateEvent = () => {
    setActiveView("createEvent"); // Switch view to CreateEvent
  };

  const cancelCreateEvent = () => {
    setActiveView("events"); // Return to EventList view
  };

  const backToDashboard = () => {
    setActiveView("dashboard"); // Reset view to dashboard
    setExpandedEvent(null); // Clear any expanded event
  };

  const showMyAccount = () => {
    setActiveView("myAccount"); // Switch view to MyAccount
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);

    // Ensure scrolling is enabled after closing the sidebar
    // if (isSidebarVisible) {
    //   document.body.classList.remove('overflow-hidden');
    // } else {
    //   document.body.classList.add('overflow-hidden');
    // }
  };

  useEffect(() => {
    // Make sure we remove 'overflow-hidden' when the component is unmounted or if sidebar is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  const handleEventDeleted = (deletedEventName) => {
    setEvents((prevEvents) => {
      const updatedEvents = { ...prevEvents };
      delete updatedEvents[deletedEventName];
      return updatedEvents;
    });
  };

  // Example goal completion calculation (you can modify this based on your app's logic)
  const eventsCreated = Object.keys(events).length;
  const eventsGoal = 20;
  const eventsProgress = (eventsCreated / eventsGoal) * 100;

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-[#FFFFFF] group/design-root">
      <Header handleSignOut={handleSignOut} toggleSidebar={toggleSidebar} />

      <div
        className={`layout-container flex h-full grow flex-row justify-center mt-[1rem] ${
          isSidebarVisible ? "sidebar-visible" : ""
        }`}
      >
        <div
          className={`absolute top-0 left-0 h-full w-full  ${
            isSidebarVisible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          onClick={toggleSidebar}
        ></div>

        {/* Sidebar */}
        <div
          className={`absolute top-0 left-0 h-full w-full md:w-[300px] z-20 bg-white transition-transform duration-300 transform ${
            isSidebarVisible ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0`}
        >
          <Sidebar
            activeView={activeView}
            onShowEvents={() => setActiveView("events")}
            onShowDashboard={() => setActiveView("dashboard")}
            onShowMyAccount={showMyAccount}
            toggleSidebar={toggleSidebar} // Pass the toggleSidebar function
            userPhotoURL={userInfo?.photoURL}
            userName={userInfo?.displayName}
          />
        </div>

        {/* Main content */}
        <div className="layout-content-container flex flex-col max-w-[960px] flex-1 w-full px-0 sm:p-6">
          {activeView === "myAccount" ? (
            <MyAccount
              onProfileUpdate={(updatedInfo) => setUserInfo(updatedInfo)}
            />
          ) : activeView === "createEvent" ? (
            <CreateEvent
              onCancel={cancelCreateEvent}
              onBackToDashboard={backToDashboard}
            />
          ) : expandedEvent ? (
            <EventDetails
              backToEventList={() => setExpandedEvent(null)}
              selectedEventDetails={events[expandedEvent] || {}}
              onEventDeleted={handleEventDeleted}
            />
          ) : activeView === "dashboard" ? (
            <div className="dashboard-section space-y-6">
              {/* Announcements Banner */}
              <div className="announcement-banner bg-blue-100 text-blue-700 p-4 rounded-lg shadow mx-4">
                🎉 <span className="font-bold">New Feature Released:</span> You
                can now send Google Calendar invitations straight from your
                event!
              </div>

              {/* Goals & User Stats Section */}
              <div className="p-4">
                <h2 className="text-black text-4xl font-black leading-tight">
                  Stats & Goals
                </h2>
                <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
                  Keep going! You're nearly there.
                </p>
                <div className="space-y-4 mt-4">
                  <div className="p-4 bg-white rounded-lg shadow">
                    <h3 className="text-lg font-semibold">✨ Events Created</h3>
                    <p className="text-sm">
                      {eventsCreated} out of {eventsGoal} events created
                    </p>
                    <div className="w-full bg-gray-300 rounded-full h-2 mt-2">
                      <div
                        className="bg-blue-500 h-2 rounded-full"
                        style={{ width: `${eventsProgress}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="p-4 bg-white rounded-lg shadow">
                    <h3 className="text-lg font-semibold">
                      🤝 Friends Invited
                    </h3>
                    <p className="text-sm">{friendsInvited} friends invited</p>
                  </div>
                  <div className="p-4 bg-white rounded-lg shadow">
                    <h3 className="text-lg font-semibold">
                      🤳 Make it personal
                    </h3>
                    <p className="text-sm">Why not add a profile picture?</p>
                  </div>
                </div>
              </div>

              {/* Upcoming Updates Section */}
              <div className="p-4">
                <h2 className="text-black text-4xl font-black leading-tight">
                  Upcoming Updates
                </h2>
                <p className="text-[#6B6B6B] font-normal leading-normal mt-2 mx-1">
                  See what we're currently working on!
                </p>
                <div className="space-y-4 mt-4">
                  <div className="p-4 bg-white rounded-lg shadow">
                    <h3 className="text-lg font-semibold">
                      💡Feature: Event Notifications
                    </h3>
                    <p className="text-sm">
                      You'll receive notifications when friends select their
                      availability.
                    </p>
                    <p className="text-xs text-gray-500 mt-1">Coming Soon</p>
                  </div>
                  <div className="p-4 bg-white rounded-lg shadow">
                    <h3 className="text-lg font-semibold">
                      💡Feature: Calendar Integration
                    </h3>
                    <p className="text-sm">
                      Sync your events with Google Calendar & invite your
                      friends.
                    </p>
                    <p className="text-xs text-gray-500 mt-1">Coming Soon</p>
                  </div>
                </div>
              </div>

              {/* Events Listing */}
              <div>
                <EventList
                  events={events}
                  toggleEvent={toggleEvent}
                  onCreateEvent={showCreateEvent}
                />
              </div>

              {/* User Feedback Section */}
              <FeedbackForm />
            </div>
          ) : (
            <EventList
              events={events}
              toggleEvent={toggleEvent}
              onCreateEvent={showCreateEvent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
